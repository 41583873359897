import ChapterPannel from "./ChapterPannel";
import Price from "./Price";
import Top from "./Top";
import Treatment from "./Treatment";
import Contact from "./Contact";
import Footer from "./Footer";
import React from "react";


function Home () {

    const chapter = [
        {
         title:"こんなお悩みはありませんか？",
         text :"EDとは「Erectile Dysfunction」の略で、男性の性機能障害の一種と言えます。具体的には「満足な性交渉ができない」状態のことで、 主に下記の症状が現れることが多いです。",
        },

        {
            title:"REJEMAXとは?",
            text :"従来のED治療は薬による一時的な効果しか見込めなかったのに対し、rejemaxはed治療の長期的な効果が期待できる最先端機器として、EDの原因にアプローチするため、これまでに培った衝撃は治療の技術や経験から、衝撃波が勃起障害を改善するというED治療専用機である。",
        },

        {
            title:"REJEMAXの効果",
            text :"リジェマックスは男性器に衝撃を当てることで海綿体を揺すります。 海綿体が刺激されることで新しい血管を作る細胞増殖因子が放出され、男性器内に新血管が生成され多くの血液が流れるようになり、その結果、勃起障害が改善されます。",
        },

        {
            title:"REJEMAXの特徴",
            text :"血管を新生してEDを改善するのがREJEMAXの特徴です。施術中の痛みがほとんど無く、 1回数分と施術時間が短いことから多くのお客様からご好評いただいております。 また、副作用の報告がほとんどなくED治療薬と併用できる点も人気となっております。            ",
        },

   
    ]

    return(
        <main className="text-white">

            <Top />

            <div className="ChapterWrapper py-24">
            {chapter && chapter.map ((content)=> (
              <ChapterPannel
                 title={content.title}
                 text={content.text}
            
            
                />

            ))}
            
            

            </div>


            <Treatment />
            <Price />
            <Contact />
            <Footer />
            
            
            

        </main>
    );
}

export default Home;