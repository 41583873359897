

import React from 'react';
import { LoadScript, GoogleMap, Marker } from '@react-google-maps/api';
import Heading2 from './Heading2';


const Map = () => {
    const containerStyle = {
        width: '100%',
        height: '400px'
      };
      
      const center = {
        lat: 34.677793,
        lng: 135.5046634
      };

      const heading2 =[
        {
            heading2 :"ZENITHへのアクセス",
        }
    ]


      return (

        <div>
                {heading2 && heading2.map ((content)=> (
            <Heading2

            heading2={content.heading2}
            
            />
        ))}    


        <div className='lg:w-1/2 m-auto'>
          <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={17}
            >
              <Marker position={center} />
            </GoogleMap>
          </LoadScript>
        </div>

        </div>
      );
    

};

export default Map;
