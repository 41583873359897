function ChapterPannel (props) {

    return (
        <div>

        <div className="HEADING w-4/5 mx-auto py-12 flex justify-center">
          <div className="h-10 w-1 bg-gold"></div> 
          <h2 className="my-auto mx-4 text-xl">{props.title}</h2>  
          <div className="h-10 w-1 bg-gold"></div>
        </div>


        <div className="w-11/12 lg:w-6/12 m-auto text-lg leading-10 text-left">
            <p>{props.text}</p>
        </div>
        
        </div>
    );
}

export default ChapterPannel;