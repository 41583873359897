import { IoIosArrowForward } from "react-icons/io";


function TreatmentPannel (props) {
    return (

        <div>
        <div className="w-4/5 lg:w-1/2 m-auto flex justify-center py-5">
        <div className="w-32">
            <div><p className="bg-gray text-gold font-bold py-2">施術</p></div>
            <div><p className="bg-gold text-black font-bold py-8 ">{props.oddnumber}回目</p></div>

         </div>


         <div className="mx-5 my-auto">
           <IoIosArrowForward className="text-6xl text-gold my-auto" />
           <p className="font-bold text-sm">3日空ける</p>
         </div>
        



         <div className="w-32">
            <div><p className="bg-gray text-gold font-bold py-2">施術</p></div>
            <div><p className="bg-gold text-black font-bold py-8 ">{props.evennumber}回目</p></div>

         </div>


        </div>

        <p className="block text-xl font-bold py-4 ">一週間空ける</p>




        </div>
 
        


    );
}


export default TreatmentPannel;