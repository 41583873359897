import React from "react";
function Top() {
    return (
        <div className="py-12">
        <p className="text-xl">アノ時の感覚を再び</p>
        <h2 className="text-2xl leading-loose">業界最新機種の<span className=" text-gold">REJEMAX</span>を使用し<br />課題の原因へ直接アプローチ</h2>
        <img className="w-1/2 lg:w-1/4 m-auto" src="https://ed-zenith.com/images/top.jpg" alt="topImage" />
     </div>
);
}

export default Top;