import Heading2 from "./Heading2";
function Price () {

    const heading2 =[
        {
            heading2 :"料金",
        }
    ]
    return (
        <div>

        {heading2 && heading2.map ((content)=> (
            <Heading2

            heading2={content.heading2}
            
            />
        ))}  

        <div className="text-black bg-white w-4/5 lg:w-1/2 m-auto my-5 rounded-lg">
          <p className="underline py-3">一回あたりの料金</p>
          <h3 className="text-4xl py-7 font-bold">¥27,500-</h3>
          <p>　</p>
        </div>  

        <div className="text-black bg-gold w-4/5 lg:w-1/2 m-auto my-12 rounded-lg">
          <p className="underline py-3">おすすめのコースとお得な回数券</p>
          <h3 className="text-4xl py-7 font-bold">¥132,000-</h3>
          <p className="py-3">6回施術分</p>
        </div>  


        <div className="text-black bg-white w-4/5 lg:w-1/2 m-auto my-5 rounded-lg">
          <p className="underline py-3">月一回のメンテナンス</p>
          <h3 className="text-4xl py-7 font-bold">¥25,200-</h3>
          <p className="py-3">6回コースご利用の方に推奨しております</p>
        </div>  



        </div>
    );
}

export default Price;