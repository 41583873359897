import TreatmentPannel from "./TreatmentPannel";
import Heading2 from "./Heading2";

function Treatment () {


    const treatment = [
        {
            oddnumber: "1",
            evennumber:"2"
        },
        {
            oddnumber: "3",
            evennumber:"4"
        },
        {
            oddnumber: "5",
            evennumber:"6"
        },


    ]



    const heading2 =[
        {
            heading2 :"施術ステップ",
        }
    ]

    return (
     <div>

                {heading2 && heading2.map ((content)=> (
            <Heading2

            heading2={content.heading2}
            
            />
        ))}    


        {treatment && treatment.map ((content)=> (
              <TreatmentPannel
                 evennumber={content.evennumber}
                 oddnumber={content.oddnumber}
            
            
                />

            ))}


     </div>
    );
}


export default Treatment;