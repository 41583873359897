import Heading2 from "./Heading2";
import Map from "./Map";
function Contact () {

    const heading2 =[
        {
            heading2 :"お問い合わせ",
        }
    ]

    return (
        <div>

{heading2 && heading2.map ((content)=> (
            <Heading2

            heading2={content.heading2}
            
            />
        ))}  
<div className="py-5"> 
<h3 className='text-xl'>営業時間</h3>
        <p>11:00~22:00(要予約)</p>

</div>
  


            <div className=" text-black w-4/5 lg:w-1/2 m-auto mb-5 ">
                <a className="block bg-green rounded-lg" href="https://line.me/R/ti/p/@083yoyoj?oat_content=url"><h3 className=" text-xl font-bold py-5">ED研究所ZENITH公式LINE</h3><p className="py-2">お申し込みやご相談はこちら！</p></a>

            
            </div>

            <Map />

            


        </div>
    );
}

export default Contact